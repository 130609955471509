import './VisitForm.css';
import FormEdit, {EditM, EditChAcord, Option} from './FormEdit.js';
import { Link, Outlet } from "react-router-dom";
import { useState} from "react";
import './AlertWindow.css';

export default function FormVisit(pr)
	{
	var pr_nume_campuri = pr.nume_campuri.split(";");
	var [alert_window, setAlertWindow] = useState("");
	
	function send_email()
		{
		if(document.getElementById("f_input_visit_acord").checked)
			{
			var formData = new FormData(document.getElementById('visit_form'));
			var xhr = new XMLHttpRequest();
			xhr.open("POST", 'https://camper.professionalservices.ro/save_visits.php', true);
			xhr.send(formData);
			xhr.onreadystatechange=function()
				{
				if (xhr.readyState===4)
					{
					if (xhr.status===200 || window.location.href.indexOf("http")===-1)
						{
						switch(xhr.responseText)
							{
							case "sent":{
										setAlertWindow("Cererea dumneavoastra a fost inregistrata.");
										clear_form();
									}
							break;
							case "1": setAlertWindow("Eroare! Lipseste numele.");
							break;
							case "2": setAlertWindow("Eroare! Lipseste emailul.");
							break;
							case "3": setAlertWindow("Eroare data.");
							break;
							default: console.log(xhr.responseText);
							}
						}
					else
						{
						setAlertWindow("Error in request.");
						}
					}
				};
			}
		else
			{
			setAlertWindow(pr.notice);
			}
		}

	function clear_form()
		{
		document.getElementById('f_input_visit_name').value = "";
		document.getElementById('f_input_visit_email').value = "";
		document.getElementById('f_input_visit_acord').checked = false;
		}

	function MyAlert()
		{
		if(alert_window !== "")
			{
			return (
				<div id="al_window">
					<div id="al_window_base" className="window_base">
					</div>
					<div id="al_window_container">
						<div id="al_window_main" className="window_main">
							<span>{alert_window}</span>
						</div>
						<div id="al_window_ctrl" className="window_ctrl">
							<button id="al_window_ctrl_btn_1" className="window_ctrl_btn" onClick={()=>{setAlertWindow("");}} type="button">OK</button>
						</div>
					</div>
				</div>
				);
			}
		else
			{
			return "";
			}
		}

	return (
		<div id="form_visit_container">
		{MyAlert()}
			<form id="visit_form">
				<Option optionData={pr.option_data} id="visit_opt" name="visit_date" />
				<FormEdit exit_text={pr_nume_campuri[1]} id="visit_name" type="text" input_name="name" />
				<FormEdit exit_text={pr_nume_campuri[2]} id="visit_email" type="text" input_name="email" />
				<EditChAcord exit_text={pr.mesaj_gdpr} id="visit_acord" input_name="form_acord" value="form_acord" />
				<EditChAcord exit_text={pr.mesaj_anunturi} id="visit_anunturi" input_name="form_anunturi" value="1" />
				<button id="visit_submit" type="button" className="res_btn f_section" onClick={()=>{send_email();}}>Submit</button>
			</form>
		<Outlet />
		</div>
	);
	}