import './Offers.css';
import Menu from '../comp/Menu.js';
import logo_main from '../images/logo.jpg';
import { useState, useEffect} from "react";
import XmlRequest from '../comp/XmlReq.js';
import { Link, useParams } from "react-router-dom";
import Formxc from '../comp/Form.js';

export default function Offers()
	{
	var server = "https://camper.professionalservices.ro/";
	
	// offersdata stores data from server
	var [offersdata, setData] = useState({lang:[], 
										menu:"",
										offers_arr:[],
										contact_title:"", 
										desc_contact:"",
										form_names:"",
										form_condition:"",
										form_notice:"",
										semnatura:"",
										link_gdpr:""
										});
	var slide_img_loc = "";
	var offers_arr = [];
	
	var url = new URL(window.location.href);
	var offer_id = url.searchParams.get("offer");

	try
		{
		offers_arr = JSON.parse(offersdata.offers_arr);
		}
	catch (e)
		{	
		}
	var check_lang = useParams().lang;
	var lang = "";
	if(useParams().lang !== undefined)
		{
		lang = check_lang;
		}
		
	if(window.innerWidth > 768)
		{
		slide_img_loc = server+"pictures/";
		}
	else
		{
		slide_img_loc = server+"pictures/small/";
		}
		
	// get data from server
	useEffect(() => {
					XmlRequest("POST", server+"get_offers.php", "offer="+offer_id+"&language="+lang).then
						(
						function(value) {
										if(value !== "")
											{
											document.getElementById("div_spinner").style.display = "none";
											}
										setData(JSON.parse(value));
										// for form planer
										//f_tcalInit();
										if(lang === "")
											{
											document.documentElement.lang ="ro";
											}
										else
											{
											document.documentElement.lang = lang;
											}
										},
						function(error) {console.log(error);}
						);
					}, [lang,offer_id]);

	return (
			<div id="div_main">
				<div id="div_spinner">
					<img id="img_spinner" src={server+"images/gear_loader.png"} alt="" />
				</div>
				<div id="top">
					<div id="logo_container">
						<a href={server}>
							<img id="logo_img" className="img_container_dim" src={logo_main} alt="logo" />
						</a>
					</div>
					<div id="top_menu">
						<Menu active_lang={lang} languages={offersdata.lang} opt_addr={"/"+lang+";/"+lang+"#Galerie-Foto;/"+lang+"#descriere;/"+lang+"#tarife;/"+lang+"#offers;/"+lang+"#Rezerva-acum"} ad_cl_to="#Rezerva-acum" />
					</div>
					<div id="top_lang">
						{offersdata.lang.map((item, id) => (
														<div key={id} className="lang_div">
															<Link to={item === "ro" ? "/Offers/?offer="+offer_id : "/Offers/"+item+"?offer="+offer_id}>
																<img id={"lang_img_"+item} className="img_container_dim" src={server+"images/"+item+".png"} alt={"language "+item} />
															</Link>
														</div>
														)
										)
						}
					</div>
				</div>
				<div id="middle_container">
					<div id="middle" className="offer_middle">
							{offers_arr.map((item, id) => (
												<div key={id} id={"offer"+id} className="offer_main">
													<img className="offer_img" src={slide_img_loc+item["img"]} alt={"offer image "+item["img"]}/>
													<div className="offer_title">
														<h3>{item["name"].replace(/<br>/g, "\r\n")}</h3>
													</div>
													<div className="offer_period">
														<h5>{item["period"]}</h5>
													</div>
													<div className="offer_text">
														<span>{item["text"].replace(/<br>/g, "\r\n")}</span>
													</div>
												</div>
												)
								)}
						<div id="rezerva-acum" className="rows-h">
							<div id="form_txt" className="row_left_div">
								<span><h1>{offersdata.contact_title}</h1></span>
								<span>{offersdata.desc_contact}</span>
							</div>
							<div id="form_input" className="row_right_div">
								<Formxc nume_campuri={offersdata.form_names} mesaj_gdpr={offersdata.form_condition} notice={offersdata.form_notice} />
							</div>
						</div>
					</div>
				</div>
				<div id="bottom">
					<span><a href="https://www.oferta-creare-website.ro">{offersdata.semnatura}</a></span>
					<Link to={"/Gdpr/"+lang}> {offersdata.link_gdpr}</Link>
				</div>
			</div>
			);
	}