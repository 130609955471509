import './FormEdit.css';

//label + input type text
export default function FormEdit(pr)
	{
	var id = pr.id.replace(" ","-");
	var cl_in = "", cl_la = "";
	var read_only = false;
	if(pr.cl_in !== "")
		{
		cl_in = " "+pr.cl_in;
		}
	if(pr.cl_la !== "")
		{
		cl_la = " "+pr.cl_la;
		}
	if(pr.read_only === "true")
		{
		read_only = true;
		}
	return (
			<div className="f_section">
				<label id={"f_label_"+id} className={"f_label"+cl_la} htmlFor={"f_input_"+id}>{pr.exit_text}</label>
				<input id={"f_input_"+id} className={"f_input"+cl_in} type={pr.type} name={pr.input_name} readOnly={read_only} value={pr.value} />
			</div>
			);
	}

//only label
export function Label(pr)
	{
	var id = pr.id.replace(" ","-");
	return (
			<div>
				<label id={"f_label_"+id} className="f_label" htmlFor={"f_input_"+id}>{pr.exit_text}</label>
			</div>
			);
	}

//input type text with or without value
export function Edit(pr)
	{
	var id = pr.id.replace(" ","-");
	var clas = "f_input";
	if(pr.type === "submit")
		{
		clas = "f_btn";
		}
	if(pr.value !== "")
		{
		return (
				<div className={"edit_cont_"+clas}>
					<input id={"f_input_"+id} className={clas} type={pr.type} name={pr.input_name} value={pr.value} />
				</div>
				);
		}
	else
		{
		return (
				<div className={"edit_cont_"+clas}>
					<input id={"f_input_"+id} className={clas} type={pr.type} name={pr.input_name} />
				</div>
				);
		}	
	}

//for textarea
export function EditM(pr)
	{
	var id = pr.id.replace(" ","-");
	return (
			<div className="f_section">
				<label id={"f_label_"+id} className="f_label" htmlFor={"f_text_"+id}>{pr.exit_text}</label>
				<textarea id={"f_text_"+id} className="f_text" name={pr.input_name}></textarea>
			</div>
			);
	}

//for Checkbox
export function EditCh(pr)
	{
	var id = pr.id.replace(" ","-");
	return (
			<div>
				<input id={"f_input_"+id} type="checkbox" name={pr.input_name} value={pr.value} />
				<label id={"f_label_"+id} className="f_label" htmlFor={"f_input_"+id}>{pr.exit_text}</label>
				<br />
			</div>
			);
	}

function acord_click(id)
	{
	document.getElementById("div_acord_txt_"+id).classList.toggle("div_acord_extend");
	document.getElementById("acord_dots_"+id).classList.toggle("acord_dots_hide");
	document.getElementById("acord_txt_end_"+id).classList.toggle("acord_txt_end_hide");
	}

//for acord Checkbox
export function EditChAcord(pr)
	{
	var id = pr.id;
	var acord_txt_begin = pr.exit_text.slice(0, 30);
	var acord_txt_end = pr.exit_text.slice(30);
	return (
			<div className="f_section div_ch_acord">
				<div id={"div_acord_ch_"+id}>
					<label id={"f_label_"+id} className="f_label_ch" htmlFor={"f_input_"+id}>
						<input id={"f_input_"+id} className="f_chd" type="checkbox" name={pr.input_name} value={pr.value} />
					</label>
				</div>
				<div id={"div_acord_txt_"+id} onClick={()=>{acord_click(id);}} className="div_acord_min">
					<span>{acord_txt_begin}</span>
					<span id={"acord_dots_"+id}>...</span>
					<span id={"acord_txt_end_"+id} className="acord_txt_end_hide">{acord_txt_end}</span>
				</div>
			</div>
			);
	}

//for Option
export function Option(pr)
	{
	return (
			<select id={pr.id} name={pr.name} className="f_opt">
				{pr.optionData.map((item, id) => (
												<option key={id} id={"opt_"+id} value={item}>{item}</option>
												)
									)
				}
			</select>
			);
	}