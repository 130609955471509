import './App.css';
import Gdpr from './pages/Gdpr.js';
import Offers from './pages/Offers.js';
import Home from './pages/Home.js';
import { BrowserRouter, Routes, Route} from "react-router-dom";

function App()
	{
	
	return (
    <div id="app_main" className="App">
		<BrowserRouter basename={'/projects/rulota/'}>
		<Routes>
			<Route index element={<Home />} />
			<Route path=":lang" element={<Home />} />
			<Route path="Offers" element={<Offers />} />
			<Route path="Offers/:lang" element={<Offers />} />
			<Route path="Gdpr" element={<Gdpr />} />
			<Route path="Gdpr/:lang" element={<Gdpr />} />
		</Routes>
		</BrowserRouter>
	</div>
  );
	}

export default App;
