import './PhotoG2.css';
import { useState } from "react";
import img_ctrl_rev from '../images/rev.png';
import img_ctrl_ff from '../images/ff.png';

var new_pos = 0;

export default function Gallery(pr)
	{
	var loc = "";
	if(window.innerWidth < 768)
		{
		loc = "https://camper.professionalservices.ro/pictures/small/";
		}
	else
		{
		loc = "https://camper.professionalservices.ro/pictures/";	
		}
	var [main_photo, setMain] = useState();
	function show_img(dir)
		{
		if(dir === "rev" && new_pos > 0)
			{
			new_pos--;
			}
		if(dir === "ff" && new_pos < pr.photo_list.length-1)
			{
			new_pos++;
			}
		setMain(pr.photo_list[new_pos]);
		}
		

		
	return (
			<div id="gallery_container">
				<div id="gal_view_photo" className="row_center_div">
					<div id="gal_left_ctrl" className="gal_img_ctrl" onClick={() => show_img('rev')}>
						<img id="left_ctrl_img" className="img_container_dim" src={img_ctrl_rev} alt="rev control" />
					</div>
					<div id="gal_img">
						<div id="div_left_ctrl" className="div_over_img" onClick={() => show_img('rev')}></div>
						<img id="gal_main_img" className="img_container_dim img_round_c" src={typeof main_photo === "undefined" ? loc+pr.photo_list[0] : loc+main_photo} alt={typeof main_photo === "undefined" ? "Big photo "+pr.photo_list[0] : "Big photo "+main_photo} />
						<div id="div_right_ctrl" className="div_over_img" onClick={() => show_img('ff')}></div>
					</div>
					<div id="gal_right_ctrl" className="gal_img_ctrl" onClick={() => show_img('ff')}>
						<img id="right_ctrl_img" className="img_container_dim" src={img_ctrl_ff} alt="ff control" />
					</div>
				</div>
				<div id="gal_all_photos" className="row_center_div">
					{pr.photo_list.map((item, index)=>(
												<div key={index} id={"photo_list_img_"+item} className="photo_list_img_div">
												<img className="img_container_dim" src={loc+item} onClick={() => {setMain(item); new_pos=index;}} alt={item} />
												</div>
												)
									)}
				</div>
				
			</div>
			);
	}