import './Form.css';
import FormEdit, {EditM, EditChAcord} from './FormEdit.js';
import { Link, Outlet } from "react-router-dom";
import { useState} from "react";
import './AlertWindow.css';

export default function Formxc(pr)
{
	var transaction_id = 10125;
	var [alert_window, setAlertWindow] = useState("");
	function send_email()
	{
		if(document.getElementById("f_input_acord").checked)
			{
			var formData = new FormData(document.getElementById('contact_form'));
			var xhr = new XMLHttpRequest();
			xhr.open("POST", 'https://camper.professionalservices.ro/send_form.php', true);
			//xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.send(formData);
			xhr.onreadystatechange=function()
				{
				if (xhr.readyState===4)
					{
					if (xhr.status===200 || window.location.href.indexOf("http")===-1)
						{
						switch(xhr.responseText)
							{
							case "sent":{
										window.dataLayer.push({
															event: 'Custom_Form_Submit',
																eventProps: {
																category: 'contact',
																action: 'none',
																label: 'Contact_Form',
																value: 'value'
																}
															});
										setAlertWindow("Cererea dumneavoastra a fost trimisa");
										clear_form();
										break;
									}
							case "1": setAlertWindow("Eroare in trimiterea emailului.");
							break;
							case "2": setAlertWindow("Eroare db in trimiterea emailului.");
							break;
							case "4": setAlertWindow("Numele nu a fost completat.");
							break;
							case "5": setAlertWindow("Perioada nu a fost completata.");
							break;
							case "6": setAlertWindow("Telefonul nu a fost completat.");
							break;
							case "7": {
										window.dataLayer.push({
															event: 'purchase',
																eventProps: {
																transaction_id: transaction_id,
																category: 'contact',
																action: 'none',
																label: 'Contact_Form',
																value: '12',
																quantity: '3',
																currency: 'EUR'
																}
															});
										setAlertWindow("Adresa de email nu a fost completata.");
										break;
									}
							default: console.log(xhr.responseText);
							}
						}
					else
						{
						setAlertWindow("Error in request.");
						}
					}
				};
			}
		else
			{
			setAlertWindow(pr.notice);
			}
	}
	
	function clear_form()
		{
		document.getElementById('f_input_perioada').value = "";
		document.getElementById('f_input_name').value = "";
		document.getElementById('f_input_email').value = "";
		document.getElementById('f_input_phone').value = "";
		document.getElementById('f_input_acord').checked = false;
		document.getElementById('f_text.message').value = " ";
		}
		
	var pr_nume_campuri = pr.nume_campuri.split(";");

	function MyAlert()
		{
		if(alert_window !== "")
			{
			return (
				<div id="al_window">
					<div id="al_window_base" className="window_base">
					</div>
					<div id="al_window_container">
						<div id="al_window_main" className="window_main">
							<span>{alert_window}</span>
						</div>
						<div id="al_window_ctrl" className="window_ctrl">
							<button id="al_window_ctrl_btn_1" className="window_ctrl_btn" onClick={()=>{setAlertWindow("");}} type="button">OK</button>
						</div>
					</div>
				</div>
				);
			}
		else
			{
			return "";
			}
		}

	return (
		<div id="form_container" className="f_cont">
		{MyAlert()}
			<form id="contact_form">
				<FormEdit exit_text={pr_nume_campuri[0]} read_only="true" cl_in="tcal" id="perioada" type="text" input_name="perioada" />
				<FormEdit exit_text={pr_nume_campuri[1]} id="name" type="text" input_name="name" />
				<FormEdit exit_text={pr_nume_campuri[2]} id="email" type="text" input_name="email" />
				<FormEdit exit_text={pr_nume_campuri[3]} id="phone" type="text" input_name="phone" />
				<EditM exit_text={pr_nume_campuri[4]} id="message" input_name="message" />
				<EditChAcord exit_text={pr.mesaj_gdpr} id="acord" input_name="form_acord" value="form_acord" />
				<button id="submit" type="button" className="res_btn" onClick={()=>{send_email();}}>Submit</button>
			</form>
		<Outlet />
		</div>
	);
}